import React, { useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Question from "../components/Question";
import QuestionEditor from "../components/QuestionEditor";
import { ExamContext, ExamContextState } from "../contextApis/examContext";
import "./QuestionsTab.scss";

const QuestionsTab: React.FC = () => {
    const examContext = useContext<ExamContextState>(ExamContext);

    return (
        <Tabs>
            <TabList>
                {
                    examContext.questionList?.map((question, index) => (
                        <Tab key={question.id}>
                            <Question question={question} index={index} key={question.id} />
                        </Tab>
                    ))
                }
            </TabList>
            {
                examContext.questionList?.map((question, index) => (
                    <TabPanel key={question.id}>
                        <QuestionEditor question={question} />
                    </TabPanel>
                ))
            }
        </Tabs>
    );
};
export default QuestionsTab;

