import React, { useState, useContext } from "react";
import InsctructionIcon from "../assets/imgs/instructions.svg";
import Timer from "./Timer";
import { ExamContext, ExamContextState } from "../contextApis/examContext";
import ConfirmationModal from "./ConfirmationModal";
import { useNavigate } from "react-router";
import { postSubmitTest } from "../services/ExamService";
import { UserContext, UserContextState } from "../contextApis/userContext";
import { useToastContext, ADD } from "../contextApis/toastContext";
import { showToastMessageOnError } from "../utils/utils";
import "./QuestionCandidateHeader.scss";

export interface examInformationProps {
    readonly openModal: () => void;
}

const QuestionCandidateHeader: React.FC<examInformationProps> = ({ openModal }) => {
    const userContext = useContext<UserContextState>(UserContext);
    const examContext = useContext<ExamContextState>(ExamContext);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const navigate = useNavigate();
    const { toastDispatch } = useToastContext();

    const onSubmitTest = () => {
        setShowConfirmationModal(true);
    }
    const onConfirm = () => {
        const candidateAssessmentId: string = examContext.examInfo?.candidateAssessmentId || "";
        postSubmitTest(candidateAssessmentId)
            .then((response: any) => {
                toastDispatch({
                    type: ADD,
                    payload: {
                        content: "Test submitted Successfully.",
                        type: "success"
                    }
                })
                setShowConfirmationModal(false);
                navigate("/submit-success/submitted");
            }).catch((error: any) => {
                showToastMessageOnError(error, userContext, toastDispatch, ADD);
                setShowConfirmationModal(false);
            });
    };

    return (
        <div className="exam-more-info">
            <div className="left-section" onClick={openModal}>
                <img src={InsctructionIcon} alt="Tech Prescient" />
                <span>Instructions</span>
            </div>
            <div className="right-section">
                <Timer examStartTime={examContext.examInfo?.examStartTime} />
                <button id="btn-submit-test" onClick={onSubmitTest}>Submit Test</button>
            </div>
            {showConfirmationModal ?
                <ConfirmationModal setShowConfirmationModal={setShowConfirmationModal} onConfirm={onConfirm}>
                    <div>
                        You will not be able to revert back to the test once you submit it. Are you sure you want to submit it?
                    </div>
                </ConfirmationModal>
                : null}
        </div>
    );
};
export default QuestionCandidateHeader;

