import { axiosForMultipartInstance, axiosInstance } from "./AxiosInstance";

const PATHS = Object.freeze({
    GET_LANGUAGES: "/api/v1/exam-sessions/languages",
    POST_CREATE_EXAM: "/api/v1/exam-sessions/candidate-assessments",
    POST_RUN_CODE: (candidateAssessmentId: string, candidateSolutionId: string, sampleInputOutputId: string) =>
        `/api/v1/candidate-assessments/${candidateAssessmentId}/solutions/${candidateSolutionId}/actions/execute?sampleInputOutputId=${sampleInputOutputId}`,
    POST_SAVE_AS_DRAFT: (candidateAssessmentId: string, candidateSolutionId: string) =>
        `/api/v1/candidate-assessments/${candidateAssessmentId}/solutions/${candidateSolutionId}`,
    POST_SAVE_AND_RUN: (candidateAssessmentId: string, candidateSolutionId: string, sampleInputOutputId: string) =>
        `/api/v1/candidate-assessments/${candidateAssessmentId}/solutions/${candidateSolutionId}/actions/save-run?sampleInputOutputId=${sampleInputOutputId}`,
    POST_SUBMIT_TEST: (candidateAssessmentId: string) =>
        `/api/v1/candidate-assessments/${candidateAssessmentId}/actions/submit`,
    PATCH_REVIEW_LATER: (candidateAssessmentId: string, candidateSolutionId: string) =>
        `/api/v1/candidate-assessments/${candidateAssessmentId}/solutions/${candidateSolutionId}`
})

export function getLanguages() {
    return axiosInstance.get(PATHS.GET_LANGUAGES);
}

export function postCreateExam() {
    return axiosInstance.post(PATHS.POST_CREATE_EXAM);
}

export function postRunCode(
    candidateAssessmentId: string,
    candidateSolutionId: string,
    code: string,
    codingLanguageId: string,
    sampleInputOutputId: string
) {
    return axiosInstance.post(PATHS.POST_RUN_CODE(candidateAssessmentId, candidateSolutionId, sampleInputOutputId), {
        "sourceCode": code,
        "codingLanguageId": codingLanguageId
    });
}

export function postSaveMcqAnswer(candidateAssessmentId: string, candidateSolutionId: string, payload: object) {
    return axiosInstance.put(PATHS.POST_SAVE_AS_DRAFT(candidateAssessmentId, candidateSolutionId), payload);
}

export function postSaveAndRunCode(
    candidateAssessmentId: string,
    candidateSolutionId: string,
    code: string,
    codingLanguageId: string,
    sampleInputOutputId: string) {
    return axiosInstance.post(PATHS.POST_SAVE_AND_RUN(candidateAssessmentId, candidateSolutionId, sampleInputOutputId), {
        "sourceCode": code,
        "codingLanguageId": codingLanguageId
    });
}

export function postSubmitTest(candidateAssessmentId: string) {
    return axiosInstance.post(PATHS.POST_SUBMIT_TEST(candidateAssessmentId));
}

export function postReviewLater(candidateAssessmentId: string, candidateSolutionId: string, payload: object) {
    return axiosInstance.patch(PATHS.PATCH_REVIEW_LATER(candidateAssessmentId, candidateSolutionId), payload);
}
