import React, { useState, useEffect } from "react";
import { QuestionList } from "../sharedTypes";
import "./McqQuestion.scss";

export interface questionprops {
    readonly question?: QuestionList;
    readonly savedAnswer?: string[];
    readonly saveMcqAnswer: (mcqAnswer: string[]) => void;
    readonly setIsAnswerSaved: (flag: boolean) => void;
}

const McqQuestion: React.FC<questionprops> = ({ question, savedAnswer, saveMcqAnswer, setIsAnswerSaved }) => {
    const [mcqAnswer, setMcqAnswer] = useState<string[]>([]);
    useEffect(() => {
        setMcqAnswer(savedAnswer || []);
    }, [savedAnswer])

    const onRadioButtonChange = (e: any) => {
        if (!localStorage.getItem("userRole")?.includes("CANDIDATE"))
            return false;

        setIsAnswerSaved(false);
        setMcqAnswer(mcqAnswer => {
            //TODO: check warning with this later as passing data to QuestionEditor state on change of radio
            saveMcqAnswer([e.target.value]);
            return [e.target.value];
        });
    }
    const onCheckboxSelected = (e: any) => {
        if (!localStorage.getItem("userRole")?.includes("CANDIDATE"))
            return false;

        setIsAnswerSaved(false);
        const { value, checked } = e.target;
        if (checked) {
            setMcqAnswer(mcqAnswer => {
                saveMcqAnswer([...mcqAnswer, e.target.value]);
                return [...mcqAnswer, e.target.value];
            });
        }
        else {
            setMcqAnswer(mcqAnswer => {
                saveMcqAnswer(mcqAnswer.filter((e) => e !== value));
                return mcqAnswer.filter((e) => e !== value);
            });
        }
    }

    return (
        <div className="mcq-question">
            {
                question && question?.mcqQuestion.mcqType == "SINGLE_SELECT" &&
                <div>
                    {
                        question?.mcqQuestion?.options.map((option, index) => {
                            return <div className="radio-button" key={option.id}>
                                <input
                                    key={option.id}
                                    id={option.id}
                                    type="radio"
                                    value={option.id}
                                    name={question.id}
                                    onChange={onRadioButtonChange}
                                    checked={mcqAnswer.includes(option.id)}
                                //disabled={!localStorage.getItem("userRole")?.includes("CANDIDATE")}
                                />
                                <span
                                    className={`
                                        ${!localStorage.getItem("userRole")?.includes("CANDIDATE") && option?.isAnswer
                                            ? 'correct-answer' : ''}
                                `}
                                > {option.value} </span>

                            </div>

                        })
                    }
                </div>
            }
            {
                question && question?.mcqQuestion.mcqType == "MULTI_SELECT" &&
                <div onChange={onCheckboxSelected}>
                    {
                        question?.mcqQuestion?.options.map((option, index) => {
                            return <div className="checkbox-button" key={option.id}>
                                <input
                                    key={option.id}
                                    id={option.id}
                                    type="checkbox"
                                    value={option.id}
                                    name={question.id}
                                    checked={mcqAnswer.includes(option.id)}
                                //disabled={!localStorage.getItem("userRole")?.includes("CANDIDATE")}
                                />
                                <span
                                    className={`
                                        ${!localStorage.getItem("userRole")?.includes("CANDIDATE") && option?.isAnswer
                                            ? 'correct-answer' : ''}
                                `}
                                > {option.value} </span>
                            </div>
                        })
                    }
                </div>
            }
        </div>
    );
};
export default McqQuestion;

