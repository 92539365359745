import React, { createContext, useState } from "react";
import { QuestionList, ExamInfo } from "../sharedTypes";

export type ExamContextState = {
    readonly questionList: QuestionList[];
    readonly examInfo: ExamInfo | undefined;
    readonly setQuestionAnswer: (questionList: QuestionList[]) => void;
    readonly setCandidateExamInfo: (examInfo: ExamInfo) => void;
    readonly resetExamInfo: () => void;
    readonly saveQuestionAnswer: (questionType: string, questionObj: QuestionList) => void;
    readonly saveReviewLater: (reviewLater: boolean, questionObj: QuestionList | undefined) => void;
};

const initialValue: ExamContextState = {
    questionList: [],
    examInfo: undefined,
    setQuestionAnswer: () => null,
    setCandidateExamInfo: () => null,
    resetExamInfo: () => null,
    saveQuestionAnswer: () => null,
    saveReviewLater: () => null
};

export const ExamContext = createContext<ExamContextState>(initialValue);
ExamContext.displayName = "ExamContext";

interface Props {
    children: React.ReactNode
}

export const ExamProvider: React.FC<Props> = ({ children }) => {
    const [questionList, setQuestionList] = useState<QuestionList[]>(
        []
    );
    const [examInfo, setExamInfo] = useState<ExamInfo | undefined>(
        undefined
    );

    const setQuestionAnswer = (questionList: QuestionList[]) => setQuestionList(questionList);
    const setCandidateExamInfo = (examInfo: ExamInfo) => setExamInfo(examInfo);

    const resetExamInfo = async () => {
        console.log("resetExamInfo...");
        await setQuestionList([])
        await setExamInfo(undefined)
    }
    const saveQuestionAnswer = (questionType: string, questionObj: QuestionList) => {
        setQuestionList(questionList.map((question) => {
            if (question.id == questionObj?.id)
                return questionObj
            else
                return question
        }))
    };

    const saveReviewLater = (reviewLater: boolean, questionObj: QuestionList | undefined) => {
        if (questionObj)
            setQuestionList(questionList.map((question) => {
                if (question.id == questionObj?.id)
                    return { ...questionObj, reviewLater: reviewLater }
                else
                    return question
            }))
    }

    return (
        <ExamContext.Provider
            value={{
                questionList,
                examInfo,
                setQuestionAnswer,
                setCandidateExamInfo,
                resetExamInfo,
                saveQuestionAnswer,
                saveReviewLater
            }}
        >
            {children}
        </ExamContext.Provider>
    );
};
