import React, { useContext } from "react";
import { useParams } from "react-router";
import { UserContext, UserContextState } from "../contextApis/userContext";
import SuccessTickIcon from "../assets/imgs/success-tick.svg";
import "./SubmitSuccessPage.scss";

const SubmitSuccessPage: React.FC = () => {
    const { status } = useParams();
    const userContext = useContext<UserContextState>(UserContext);
    const { given_name, family_name } = userContext.getUserDetails();
    return (
        <>
            {
                status && status == "completed" &&
                <div className="submitSuccessPage">
                    <div className="exam-completed-message">Exam already taken by the user.</div>
                </div>
            }
            {
                status && status == "submitted" &&
                <div className="submitSuccessPage">
                    <div className="success-icon">
                        <img src={SuccessTickIcon} alt="" />
                    </div>
                    <div className="thank-you-message">
                        Thank you {given_name} {family_name} for writing the exam.
                    </div>
                    <div className="submitted-check">
                        Your response is successfully submitted.
                    </div>
                    <div className="review-information">
                        <div>
                            Your review is already in progress. We will soon get back  to you.
                        </div>
                        {/* TODO: COnfirm this Greasemonkey script firefox extention compose URL */}
                        <div>
                            For any quieries please reach out to <a href="https://mail.google.com/mail/?view=cm&fs=1&to=campus@techprescient.com" rel="noreferrer" target="_blank">campus@techprescient.com</a>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
export default SubmitSuccessPage;

