import React, { useState, useContext } from "react";
import TimerIcon from "../assets/imgs/timer.svg";
import BackIcon from "../assets/imgs/back.svg";
import { ExamContext, ExamContextState } from "../contextApis/examContext";
import moment from "moment";
import { useNavigate } from "react-router";
import "./QuestionAdminHeader.scss";
import AssessmentReview from "../components/AssessmentReview";

export interface questionAdminHeaderProps {
    readonly candidateInfo: any
}

const QuestionAdminHeader: React.FC<questionAdminHeaderProps> = ({ candidateInfo }) => {
    const examContext = useContext<ExamContextState>(ExamContext);
    let totalMarks: any = 0;
    if (examContext.questionList && examContext.questionList.length > 0) {
        examContext.questionList.map((question) => {
            totalMarks = totalMarks + (question?.maxMarks > 0 ? question?.maxMarks : 0);
        })
    }
    const navigate = useNavigate();
    const onBackLinkClick = () => {
        navigate(`/search`);
    };

    return (
        <div className="exam-admin-info">
            <div className="back-to-list" onClick={onBackLinkClick}>
                <img src={BackIcon} alt="" />
                <span>Back</span>
            </div>
            <div className="user_detail">
                <span>{candidateInfo?.name}</span>
                <a href={candidateInfo?.resumeUrl} rel="noreferrer" target="_blank">View Resume</a>
            </div>
            <div className="examStartTime">
                <img src={TimerIcon} alt="" />
                <span>{
                    examContext?.examInfo?.examStartTime &&
                    moment(examContext?.examInfo?.examStartTime).format("MMMM Do YYYY, hh:mm:ss")
                }</span>
            </div>
            <div className="total_marks">
                <span className="label">Total Marks: </span>
                <span className="marks">{candidateInfo?.totalMarks}/{totalMarks}</span>
            </div>
            <div className="admin-action">
                <AssessmentReview />
            </div>
        </div>
    );
};
export default QuestionAdminHeader;

