import React, { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { isUserAuthenticated } from "../utils/utils";
import jwtDecode from "jwt-decode";

interface tokenPayload {
    given_name?: string;
    family_name?: string;
    email?: string;
    name?: string;
}
export type UserContextState = {
    readonly loading: boolean;
    readonly isAuthenticated: boolean;
    readonly onLogin: (IdToken: string) => void;
    readonly getUserDetails: () => tokenPayload;
    readonly onLogout: (path?: string) => void;
    readonly changeLoaderStatus: (flag?: boolean) => void;
};

const initialValue: UserContextState = {
    loading: false,
    isAuthenticated: false,
    onLogin: (IdToken: string) => null,
    getUserDetails: () => ({}),
    onLogout: () => null,
    changeLoaderStatus: () => null
};

export const UserContext = createContext<UserContextState>(initialValue);
UserContext.displayName = "UserContext";

interface Props {
    children: React.ReactNode
}

export const UserProvider: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(isUserAuthenticated());

    const onLogin = (IdToken: string) => {
        localStorage.setItem("IdToken", IdToken);
        setIsAuthenticated(true);
    }

    const getUserDetails = (): object => {
        const IdToken = localStorage.getItem("IdToken") || ".." as string;
        try {
            const payload: any = jwtDecode(IdToken);
            return payload as object;
        }
        catch {
            return {}
        }
    }

    const onLogout = (path?: string) => {
        setIsAuthenticated(false);
        setLoading(false);
        navigate(path || "/");
        localStorage.clear();
    };

    const changeLoaderStatus = (flag?: boolean) => setLoading(flag || false);

    return (
        <UserContext.Provider
            value={{
                loading,
                isAuthenticated,
                onLogin,
                getUserDetails,
                onLogout,
                changeLoaderStatus
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
