import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import TechPrescientImageIcon from "../assets/imgs/tech_prescient_dark.svg";
import TechPrescientDarkImageIcon from "../assets/imgs/tech_prescient_white.svg";
import { UserContext, UserContextState } from "../contextApis/userContext";
import { ExamContext, ExamContextState } from "../contextApis/examContext";
import "./AppHeader.scss";

export const AppHeader: React.FC = () => {
    const location = useLocation();
    const IsQuestionsRoute = location.pathname.includes("questions");
    const userContext = useContext<UserContextState>(UserContext);
    const examContext = useContext<ExamContextState>(ExamContext);
    const onLogout = () => {
        userContext.onLogout();
        console.log("resetExamInfo in Logout")
        examContext.resetExamInfo();
    }
    const { name } = userContext.getUserDetails() || "";

    return (
        <div className={`AppHeader ${IsQuestionsRoute ? "dark" : ""}`}>
            <img className="logo" src={IsQuestionsRoute ? TechPrescientDarkImageIcon : TechPrescientImageIcon} alt="Tech Prescient" />
            <div className={`profile-wrpr ${IsQuestionsRoute ? "dark-theme-logout" : "white-theme-logout"}`}>
                <span className="username">{`${name}`}</span>
                <span className="logout" onClick={onLogout}>Logout</span>
            </div>
        </div>
    );
};