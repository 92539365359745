import React, { useEffect, useState, useContext } from "react";
import SearchControl from "../components/SearchControl";
import CandidateTable from "../components/CandidateTable";
import { CandidateList, CandidateListCount } from "../sharedTypes";
import { getCandidateList } from "../services/AdminService";
import { UserContext, UserContextState } from "../contextApis/userContext";
import { ExamContext, ExamContextState } from "../contextApis/examContext";
import { useToastContext, ADD } from "../contextApis/toastContext";
import { showToastMessageOnError } from "../utils/utils";
import "./SearchPage.scss";

const SearchPage: React.FC = () => {
    const [searchText, setSearchText] = useState<string>("");
    const [candidateList, setCandidateList] = useState<CandidateList[]>([]);
    const [candidateListCount, setCandidateListCount] = useState<CandidateListCount>();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const userContext = useContext<UserContextState>(UserContext);
    const examContext = useContext<ExamContextState>(ExamContext);
    const { toastDispatch } = useToastContext();

    useEffect(() => {
        if (searchText == "") {
            setCandidateList([]);
            setCandidateListCount(undefined);
        }
        else {
            userContext.changeLoaderStatus(true);
            getCandidateList(searchText?.trim(), currentPage - 1, pageSize).then((response: any) => {
                userContext.changeLoaderStatus(false);
                setCandidateList(response?.data?.candidates);
                setCandidateListCount(response?.data?.pageDetailResponse);
            }).catch((error: any) => {
                showToastMessageOnError(error, userContext, toastDispatch, ADD);
            });
        }
    }, [searchText, currentPage])

    useEffect(() => {
        examContext.resetExamInfo();
    }, [])

    return (
        <div className="searchPage">
            <SearchControl saveSearchText={setSearchText} setCurrentPage={setCurrentPage} />
            <CandidateTable candidateList={candidateList} candidateListCount={candidateListCount} currentPage={currentPage} pageSize={pageSize} setCurrentPage={setCurrentPage} />
        </div>
    );
};
export default SearchPage;

