import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import QuestionsTab from "../components/QuestionsTab";
import Modal from "../components/Modal";
import { InstructionList } from "../data/instructionList";
import { ExamContext, ExamContextState } from "../contextApis/examContext";
import { postCreateExam } from "../services/ExamService";
import { getCandidateAssessment } from "../services/AdminService";
import { ExamInfo, CandidateInfo } from "../sharedTypes";
import { useToastContext, ADD } from "../contextApis/toastContext";
import { UserContext, UserContextState } from "../contextApis/userContext";
import { showToastMessageOnError } from "../utils/utils";
import { useParams, useLocation } from "react-router";
import QuestionCandidateHeader from "../components/QuestionCandidateHeader";
import QuestionAdminHeader from "../components/QuestionAdminHeader";
import "./QuestionsPage.scss";

const QuestionsPage: React.FC = () => {
    const { toastDispatch } = useToastContext();
    const userContext = useContext<UserContextState>(UserContext);
    const examContext = useContext<ExamContextState>(ExamContext);
    const { state } = useLocation();
    const candidateInfo: any = state;
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        examContext.resetExamInfo();
        if (localStorage.getItem("userRole")?.includes("CANDIDATE")) {
            userContext.changeLoaderStatus(true);
            postCreateExam().then((response: any) => {
                userContext.changeLoaderStatus(false);
                const examInfo: ExamInfo = {
                    examStartTime: response?.data?.examStartTime,
                    status: response?.data?.status,
                    candidateAssessmentId: response?.data?.candidateAssessmentId,
                    totalQuestions: response?.data?.totalQuestions
                }
                examContext.setCandidateExamInfo(examInfo);
                examContext.setQuestionAnswer(response?.data?.assessment?.quesSolList);
            }).catch((error: any) => {
                if (error?.response?.status == 422) {
                    userContext.changeLoaderStatus(false);
                    navigate("/submit-success/completed");
                }
                else
                    showToastMessageOnError(error, userContext, toastDispatch, ADD);
            });
        }
        if (localStorage.getItem("userRole")?.includes("INTERVIEWER")) {
            userContext.changeLoaderStatus(true);
            getCandidateAssessment(candidateInfo?.assessmentId as string).then((response: any) => {
                userContext.changeLoaderStatus(false);
                console.log("response from getCandidateAssessment", response)
                const examInfo: ExamInfo = {
                    examStartTime: response?.data?.examStartTime,
                    status: response?.data?.status,
                    candidateAssessmentId: response?.data?.candidateAssessmentId,
                    totalQuestions: response?.data?.totalQuestions
                }
                examContext.setCandidateExamInfo(examInfo);
                examContext.setQuestionAnswer(response?.data?.assessment?.quesSolList);
            }).catch((error: any) => {
                showToastMessageOnError(error, userContext, toastDispatch, ADD);
            });
        }

        return (() => {
            console.log("resetExamInfo in QuestionPage")
            examContext.resetExamInfo();
        })
    }, [])

    const openModal = () => {
        setShowModal(true);
    };

    return (
        <>
            {
                examContext?.examInfo?.candidateAssessmentId != "" ?
                    <div className="questionsPage">
                        {
                            localStorage.getItem("userRole")?.includes("CANDIDATE") &&
                            <QuestionCandidateHeader openModal={openModal} />
                        }
                        {
                            localStorage.getItem("userRole")?.includes("INTERVIEWER") &&
                            <QuestionAdminHeader candidateInfo={candidateInfo} />
                        }
                        <div className="questionsDetail">
                            <QuestionsTab />
                        </div>
                        {showModal ?
                            <Modal setShowModal={setShowModal}>
                                <div className="instructions">

                                    {
                                        InstructionList && InstructionList.map((instruction, index) => (
                                            <p key={instruction.id}>{instruction.description}</p>
                                        ))
                                    }
                                </div>
                            </Modal>
                            : null}
                    </div>
                    :
                    <div className="no-candidate-profile"> No Candidate Assessment found for this user.</div>
            }
        </>
    );
};
export default QuestionsPage;

