import React, { useState } from "react";
import { RunCodeResponse } from "../sharedTypes";
import "./CodeSnippetOutput.scss";

export interface codeSnippetOutputProps {
    readonly runCodeResponse?: RunCodeResponse;
}

const CodeSnippetOutput: React.FC<codeSnippetOutputProps> = ({ runCodeResponse }) => {
    return (
        <div id="codeSnippetOutput">
            <div className="header">Code Result:
                {
                    runCodeResponse &&
                    <>
                        {
                            runCodeResponse?.matchesExpectedOutput ?
                                <span className="passed-test-case">Test case passed</span>
                                : <span className="failed-test-case">Test case failed</span>
                        }
                    </>
                }
            </div>{
                runCodeResponse ?
                    <>
                        {
                            runCodeResponse?.error &&
                            <div className="response-detail">
                                <div className="response-detail-label">Error: </div>
                                {
                                    runCodeResponse?.error.split('\n').map((str, i) => <p key={i}>{str}</p>)
                                }
                            </div>
                        }
                        {
                            runCodeResponse?.errorType &&
                            <div className="response-detail">
                                <div className="response-detail-label">Error Type: </div>
                                {
                                    runCodeResponse?.errorType.split('\n').map((str, i) => <p key={i}>{str}</p>)
                                }
                            </div>
                        }
                        {
                            runCodeResponse?.providedInput &&
                            <div className="response-detail">
                                <div className="response-detail-label">Provided Input: </div>
                                {
                                    runCodeResponse?.providedInput.split('\n').map((str, i) => <p key={i}>{str}</p>)
                                }
                            </div>
                        }
                        {
                            runCodeResponse?.returnedOutput &&
                            <div className="response-detail">
                                <div className="response-detail-label">Returned Output: </div>
                                {
                                    runCodeResponse?.returnedOutput.split('\n').map((str, i) => <p key={i}>{str}</p>)
                                }
                            </div>
                        }
                        {
                            runCodeResponse?.expectedOutput &&
                            <div className="response-detail">
                                <div className="response-detail-label">Expected Output: </div>
                                {
                                    runCodeResponse?.expectedOutput.split('\n').map((str, i) => <p key={i}>{str}</p>)
                                }
                            </div>
                        }
                    </>
                    : null
            }
        </div>
    );
};


export default CodeSnippetOutput;