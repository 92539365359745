import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import { DropDownOption } from "../sharedTypes";

import 'ace-builds/src-noconflict/ace';
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-ambiance";
import "ace-builds/src-noconflict/ext-language_tools";
import "./CodeEditorWindow.scss";

export interface codeEditorProps {
    readonly selectedLanguage?: DropDownOption;
    readonly savedCode?: string;
    readonly saveCode: (code: string) => void;
    readonly setIsAnswerSaved: (flag: boolean) => void;
}

const CodeEditorWindow: React.FC<codeEditorProps> = ({ selectedLanguage, savedCode, saveCode, setIsAnswerSaved }) => {
    const [code, setCode] = useState<string>("");

    const eventListener = (e: any) => {
        // TODO: class name is container class name, get it as param
        if (e.target.closest(".codeEditor"))
            e.preventDefault();
    }
    useEffect(() => {
        document.addEventListener("contextmenu", eventListener)

        return (() => {
            document.removeEventListener("contextmenu", eventListener)
        })
    }, [])

    useEffect(() => {
        setCode(savedCode || "");
    }, [savedCode])

    const handleEditorChange = (e: any) => {
        setCode(e);
        setIsAnswerSaved(false);
    }
    const handleEditorBlur = () => {
        saveCode(code);
    }

    return (
        <AceEditor
            mode={selectedLanguage?.codeEditorMode || "java"}
            theme="ambiance"
            fontSize={14}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            onChange={handleEditorChange}
            onBlur={handleEditorBlur}
            name="ace_code_editor"
            value={code || selectedLanguage?.codeSckeleton}
            editorProps={{ $blockScrolling: Infinity }}
            commands={[
                // commands is array of key bindings.
                {
                    name: 'pasteline', //name for the key binding.
                    bindKey: { win: 'Ctrl-V', mac: 'Command-V' }, //key combination used for the command.
                    exec: function (editor) {
                        const editorEvents = ['dragenter', 'dragover', 'dragend', 'dragstart', 'dragleave', 'drop'];
                        for (const events of editorEvents) {
                            editor.container.addEventListener(events, function (e) { e.stopPropagation(); }, true);
                        }
                    }
                }
            ]}
        />
    );
};


export default CodeEditorWindow;