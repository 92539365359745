import React from "react";
import { useLocation } from "react-router-dom";
import "./AppFooter.scss";
import moment from "moment";
import ClassNames from "classnames";
export const AppFooter: React.FC = () => {
    const location = useLocation();
    const IsQuestionsRoute = location.pathname.includes("questions");
    const divClass = ClassNames({
        AppFooter: true,
        dark: IsQuestionsRoute
    })
    return (
        <div className={divClass}>
            <footer> <small>{moment().year()} Copyright &copy; | Tech Prescient | All rights reserved</small> </footer>
        </div>
    );
};