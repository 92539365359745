import { axiosInstance, axiosForMultipartInstance } from "./AxiosInstance";

const PATHS = Object.freeze({
    GET_USER_ROLE: "/api/v1/me",
    PUT_CANDIDATE_PROFILE: () =>
        `/api/v1/candidates`,
    GET_CANDIDATE_PROFILE: (candidateId: string) =>
        `/api/v1/candidates/${candidateId}`,
})

export function getUserRole() {
    return axiosInstance.get(PATHS.GET_USER_ROLE);
}

export function putCandidateProfile(formData: FormData) {
    return axiosForMultipartInstance.put(PATHS.PUT_CANDIDATE_PROFILE(), formData);
}

export function getCandidateProfile(candidateId: string) {
    return axiosInstance.get(PATHS.GET_CANDIDATE_PROFILE(candidateId));
}
