import React, { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext, UserContextState } from "../contextApis/userContext";

const CallbackPage: React.FC = () => {
    const navigate = useNavigate();
    const userContext = useContext<UserContextState>(UserContext);
    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const IdToken = (new URLSearchParams(hash).get('#id_token') || new URLSearchParams(hash).get('id_token')) as string;
            if (IdToken) {
                userContext.onLogin(IdToken);
                navigate("/");
            }
            else {
                console.error('ID token not found');
            }
        }
    }, [])
    return (
        <></>
    )
}

export default CallbackPage