import React from "react";
import { AppHeader } from "../components/AppHeader";
import { AppFooter } from "../components/AppFooter";
import Loader from "../components/Loader";
import "./DefaultLayout.scss";

interface Props {
    children: React.ReactNode
}

export const DefaultLayout: React.FC<Props> = ({ children }) => {
    return (
        <div className="DefaultLayout">
            <AppHeader />
            <div className="container">
                {children}
            </div>
            {/* TODO: Confirm if required on Login Page also */}
            <AppFooter />
            <Loader />
        </div>
    );
};
