import React, { useEffect, useState, useContext } from "react";
import QuestionDescription from "../components/QuestionDescription";
import CodeEditorWindow from "../components/CodeEditorWindow";
import CodeSnippetOutput from "../components/CodeSnippetOutput";
import DropDownComponent from "../components/DropDownComponent";
import McqQuestion from "../components/McqQuestion";
import { ExamContext, ExamContextState } from "../contextApis/examContext";
import { UserContext, UserContextState } from "../contextApis/userContext";
import { CodeSolution, DropDownOption, MCQSolution, QuestionList, RunCodeResponse } from "../sharedTypes";
import { getLanguages, postRunCode, postSaveAndRunCode, postSaveMcqAnswer, postReviewLater } from "../services/ExamService";
import { useToastContext, ADD } from "../contextApis/toastContext";
import { showToastMessageOnError } from "../utils/utils";
import "./QuestionEditor.scss";

export interface euestionsEditorProps {
    readonly question?: QuestionList;
    readonly index?: number;
}

const QuestionEditor: React.FC<euestionsEditorProps> = ({ question, index }) => {
    const examContext = useContext<ExamContextState>(ExamContext);
    const userContext = useContext<UserContextState>(UserContext);
    const [selectedLanguage, setSelectedLanguage] = useState<DropDownOption>({ value: "", label: "", codeEditorMode: "", codeSckeleton: "" });
    const [dropdownOptions, setDropdownOptions] = useState<DropDownOption[]>([]);
    const [code, setCode] = useState<string>("");
    const [runCodeResponse, setRunCodeResponse] = useState<RunCodeResponse | undefined>(
        undefined
    );
    const [mcqAnswer, setMcqAnswer] = useState<string[]>([]);
    const [reviewLater, setReviewLater] = useState<boolean>();
    const { toastDispatch } = useToastContext();
    const [isAnswerSaved, setIsAnswerSaved] = useState(true);

    useEffect(() => {
        getLanguages().then((response: any) => {
            const languageList: DropDownOption[] = [];
            if (response?.data && response?.data.length > 0) {
                response?.data.forEach((language: any) => {
                    language.codeEditorMode = function () {
                        switch (language.name) {
                            case "Java":
                                return "java"
                            case "C":
                                return "c_cpp" //confirm mode in ace document
                            case "C++":
                                return "c_cpp"
                            case "Python":
                                return "python"
                            case "JavaScript":
                                return "javascript"
                        }
                    }();
                    language.codeSckeleton = function () {
                        switch (language.name) {
                            case "Java":
                                return `import java.io.*;
import java.util.*;
public class Main {
    public static void main(String args[] ) throws Exception {
        /* Please add your code over here. Read the input in the given format from STDIN and print the output to STDOUT */
    }
}`
                            case "C":
                                return `#include <stdio.h>
#include <string.h>
#include <stdlib.h>

int main() {
    /* Please add your code over here. Read the input in the given format from STDIN and print the output to STDOUT */
    return 0;
}`
                            case "C++":
                                return `#include <iostream>
using namespace std;

int main() {
    /* Please add your code over here. Read the input in the given format from STDIN and print the output to STDOUT */
    return 0;
}`
                            case "Python":
                                return `# Please add your code over here. Read the input in the given format from STDIN and print the output to STDOUT`
                            case "JavaScript":
                                return `// Please add your code over here. Read the input in the given format from STDIN and print the output to STDOUT`
                        }
                    }();
                    languageList.push({
                        value: language.coding_language_id,
                        label: language.name,
                        codeEditorMode: language.codeEditorMode,
                        codeSckeleton: language.codeSckeleton
                    })
                })
                //TODO: check concurrent state updates
                setDropdownOptions(languageList);

            }
        }).catch((error: any) => {
            showToastMessageOnError(error, userContext, toastDispatch, ADD);
        });
        if (question?.type == "CODE") {
            setCode(question?.codeSolution?.answer);
        }
        if (question?.type == "MCQ") {
            setMcqAnswer(question?.mcqSolution?.selectedOptions);
        }
        setReviewLater(question?.reviewLater)
    }, []);

    useEffect(() => {
        if (dropdownOptions.length > 0 && question?.type == "CODE" && question?.codeSolution && question?.codeSolution?.codingLanguageId) {
            dropdownOptions?.forEach((option) => {
                if (option?.value == question?.codeSolution?.codingLanguageId) {
                    setSelectedLanguage(option);
                }
            })
        }
    }, [dropdownOptions])

    useEffect(() => {
        return (() => {
            if (question && question?.type == "CODE" && code && code != "" && selectedLanguage.value != "") {
                setQuestionAnswer(question)
            }
            if (question && question?.type == "MCQ" && mcqAnswer && mcqAnswer.length > 0) {
                setQuestionAnswer(question)
            }
        });
    }, [code, mcqAnswer, selectedLanguage, reviewLater])

    const handleLanguageChange = (language: any) => {
        setSelectedLanguage(language);
    }

    const onSaveAndRunCode = (question: QuestionList) => {
        if (code == "" || selectedLanguage?.value == "") {
            toastDispatch({
                type: ADD,
                payload: {
                    content: "Language and Code is required.",
                    type: "info"
                }
            });
            return;
        }
        userContext.changeLoaderStatus(true);
        setQuestionAnswer(question);
        const candidateAssessmentId: string = examContext.examInfo?.candidateAssessmentId || "";
        const sampleInputOutputId: string = question?.codeQuestion?.sampleTestCases[0].id || "";
        postSaveAndRunCode(candidateAssessmentId, question?.id, code, selectedLanguage?.value as string, sampleInputOutputId)
            .then((response: any) => {
                userContext.changeLoaderStatus(false);
                setIsAnswerSaved(true);
                if (response?.status == 200 && response?.data) {
                    toastDispatch({
                        type: ADD,
                        payload: {
                            content: "Code executed Successfully.",
                            type: "success"
                        }
                    });
                    const responseObj: RunCodeResponse = {
                        error: response?.data?.error,
                        errorType: response?.data?.errorType,
                        expectedOutput: response?.data?.expectedOutput,
                        matchesExpectedOutput: response?.data?.matchesExpectedOutput,
                        memory: response?.data?.memory,
                        providedInput: response?.data?.providedInput,
                        returnedOutput: response?.data?.returnedOutput,
                        time: response?.data?.time
                    }
                    setRunCodeResponse(responseObj);
                }
            }).catch((error: any) => {
                showToastMessageOnError(error, userContext, toastDispatch, ADD);
            });
    }

    const onSaveMcqAnswer = (question: QuestionList) => {
        if (mcqAnswer.length == 0) {
            toastDispatch({
                type: ADD,
                payload: {
                    content: "Answer is required.",
                    type: "info"
                }
            });
            return;
        }
        userContext.changeLoaderStatus(true);
        setQuestionAnswer(question);
        const candidateAssessmentId: string = examContext.examInfo?.candidateAssessmentId || "";
        let payload = {}
        payload = {
            "mcqSolution": {
                "selectedOptions": mcqAnswer || []
            }
        }
        postSaveMcqAnswer(candidateAssessmentId, question?.id, payload)
            .then((response: any) => {
                userContext.changeLoaderStatus(false);
                setIsAnswerSaved(true);
                if (response?.status == 204) {
                    toastDispatch({
                        type: ADD,
                        payload: {
                            content: question?.type == "CODE" ? "Code Saved Successfully." : "Answer Saved Successfully.",
                            type: "success"
                        }
                    })
                }
            }).catch((error: any) => {
                showToastMessageOnError(error, userContext, toastDispatch, ADD);
            });
    }

    const setQuestionAnswer = (question: QuestionList) => {
        const questionCodeSolution: CodeSolution = {
            answer: code,
            codingLanguageId: selectedLanguage?.value || ""
        }
        const questionMcqSolution: MCQSolution = {
            selectedOptions: mcqAnswer || []
        }
        const questionObj: QuestionList = { ...question };
        if (question?.type == "CODE") {
            questionObj.codeSolution = questionCodeSolution;
        }
        if (question?.type == "MCQ") {
            questionObj.mcqSolution = questionMcqSolution;
        }
        questionObj.reviewLater = reviewLater;
        examContext.saveQuestionAnswer(question?.type, questionObj);
    }

    const saveReviewLater = () => {
        userContext.changeLoaderStatus(true);
        setReviewLater(!question?.reviewLater)
        const payload = {
            reviewLater: !question?.reviewLater || false
        }
        const candidateAssessmentId: string = examContext.examInfo?.candidateAssessmentId || "";
        postReviewLater(candidateAssessmentId, question?.id as string, payload)
            .then((response: any) => {
                userContext.changeLoaderStatus(false);
                if (response?.status == 204) {
                    toastDispatch({
                        type: ADD,
                        payload: {
                            content: `Question ${!question?.reviewLater ? 'marked' : 'unmarked'} as review later successfully.`,
                            type: "success"
                        }
                    })
                    examContext.saveReviewLater(!question?.reviewLater, question);
                }
            }).catch((error: any) => {
                showToastMessageOnError(error, userContext, toastDispatch, ADD);
            });
        question && setQuestionAnswer(question);
    }

    return (
        <div className="question-editor">
            <QuestionDescription question={question} saveReviewLater={saveReviewLater} />
            <div className="right-section">
                <div className="code-actions">
                    {
                        localStorage.getItem("userRole")?.includes("INTERVIEWER") &&
                        <div className="button-panel">
                            {
                                question?.type == "CODE" &&
                                <input className="selected-language-text" type="text" defaultValue={selectedLanguage?.label} readOnly={true} />
                            }
                        </div>
                    }
                    {
                        localStorage.getItem("userRole")?.includes("CANDIDATE") &&
                        <div className="button-panel">
                            {
                                question?.type == "CODE" &&
                                <DropDownComponent
                                    theme="dark"
                                    options={dropdownOptions}
                                    selectedLanguage={selectedLanguage}
                                    handleLanguageChange={handleLanguageChange}
                                />


                            }
                            <div className="button-list">
                                {
                                    question?.id && question?.type == "CODE" &&
                                    <>
                                        <div className="tooltip">
                                            <button id="btn-run-code" onClick={() => onSaveAndRunCode(question)}>
                                                Save and Run
                                                {
                                                    !isAnswerSaved && <span className="bounce">!</span>
                                                }
                                            </button>
                                            <span className="tooltiptext">Save your answer</span>
                                        </div>
                                    </>
                                }
                                {
                                    question?.id && question?.type == "MCQ" &&
                                    <>
                                        <div className="tooltip">
                                            <button id="btn-save-code" onClick={() => onSaveMcqAnswer(question)}>
                                                Save
                                                {
                                                    !isAnswerSaved && <span className="bounce">!</span>
                                                }
                                            </button>
                                            <span className="tooltiptext">Save your answer</span>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>
                {
                    question?.type == "MCQ" &&
                    <div className="mcqEditor">
                        <McqQuestion question={question} savedAnswer={mcqAnswer} saveMcqAnswer={setMcqAnswer} setIsAnswerSaved={setIsAnswerSaved} />
                    </div>
                }
                {
                    question?.type == "CODE" &&
                    <div
                        className={`codeEditor ${localStorage.getItem("userRole")?.includes("CANDIDATE") ? 'candidate_view' : 'interviewer_view'} `}>
                        <CodeEditorWindow selectedLanguage={selectedLanguage} savedCode={code} saveCode={setCode} setIsAnswerSaved={setIsAnswerSaved} />
                        {
                            localStorage.getItem("userRole")?.includes("CANDIDATE") &&
                            <CodeSnippetOutput runCodeResponse={runCodeResponse} />
                        }
                    </div>
                }
            </div>
        </div>
    );
};
export default QuestionEditor;