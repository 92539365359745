export const MarkingList = [
    {
        level: "Easy",
        type: "MCQ",
        questions: 2,
        marks: 2,
        color: "#38803E",
        bgColor: "#E8FFED",
        bxShdw: "rgba(1, 144, 33, 0.25)"
    },
    { 
        level: "Simple",
        type: "MCQ",
        questions: 2,
        marks: 4,
        color: "#B87100",
        bgColor: "#FFF6E4",
        bxShdw: "rgba(225, 150, 3, 0.25)"
    },
    {
        level: "Medium",
        type: "Programming",
        questions: 3,
        marks: 12,
        color: "#B78522",
        bgColor: "#F2D8A4",
        bxShdw: "rgba(225, 150, 3, 0.25)"
    },
    {
        level: "Complex",
        type: "Programming",
        questions: 3,
        marks: 24,
        color: "#C62828",
        bgColor: "#FFF0F0",
        bxShdw: "rgba(233, 84, 84, 0.25)"
    }
]