import { axiosInstance } from "./AxiosInstance";

const PATHS = Object.freeze({
    GET_CANDIDATE_LIST: (nameLike: string, page: number, size: number) =>
        `/api/v1/candidates?page=${page}&size=${size}${nameLike ? '&nameLike=' + `${nameLike}` : ''}`,
    GET_CANDIDATE_ASSESSMENT: (candidateAssessmentId: string) =>
        `/api/v1/candidate-assessments/${candidateAssessmentId}`,
    PATCH_CANDIDATE_REVIEW: (candidateAssessmentId: string) =>
        `/api/v1/candidate-assessments/${candidateAssessmentId}`
})

export function getCandidateList(nameLike: string, page: number, size: number) {
    return axiosInstance.get(PATHS.GET_CANDIDATE_LIST(nameLike, page, size));
}

export function getCandidateAssessment(candidateAssessmentId: string) {
    return axiosInstance.get(PATHS.GET_CANDIDATE_ASSESSMENT(candidateAssessmentId));
}

export function patchCandidateReview(candidateAssessmentId: string, candidateReviewedStatus: string) {
    return axiosInstance.patch(PATHS.PATCH_CANDIDATE_REVIEW(candidateAssessmentId), {
        candidateReviewedStatus: candidateReviewedStatus
    });
}