import React from "react";
import { QuestionList } from "../sharedTypes";
import "./QuestionDescription.scss";

export interface questionprops {
    readonly question?: QuestionList;
    readonly saveReviewLater: () => void;
}

const QuestionDescription: React.FC<questionprops> = ({ question, saveReviewLater }) => {
    const onReviewLater = () => {
        saveReviewLater();
    }

    return (
        <div className="question-desc">
            {
                localStorage.getItem("userRole")?.includes("CANDIDATE") &&
                <div className="review-later-wrapper">
                    <span className="review-later" onClick={onReviewLater}>Review Later</span>
                </div>
            }
            {
                question?.type == "MCQ" ?
                    <p>{question?.title || ""}</p>
                    : <>
                        {
                            question?.description?.split('\n').map((str, i) => <p key={i}>{str}</p>)
                        }
                    </>
            }
            {
                question?.type == "CODE" && question?.codeQuestion?.explanation &&
                <div className="input-formats">
                    <div className="header">Explaination:</div>
                    <div className="input-format">
                        {
                            question?.codeQuestion?.explanation.split('\n').map((str, i) => <p key={i}>{str}</p>)
                        }
                    </div>
                </div>
            }
            {
                question?.type == "CODE" && question?.codeQuestion?.inputFormat &&
                <div className="input-formats">
                    <div className="header">Input Format:</div>
                    <div className="input-format">
                        {
                            question?.codeQuestion?.inputFormat.split('\n').map((str, i) => <p key={i}>{str}</p>)
                        }
                    </div>
                </div>
            }
            {
                question?.type == "CODE" && question?.codeQuestion?.outputFormat &&
                <div className="input-formats">
                    <div className="header">Output Format:</div>
                    <div className="input-format">
                        {
                            question?.codeQuestion?.outputFormat.split('\n').map((str, i) => <p key={i}>{str}</p>)
                        }
                    </div>
                </div>
            }
            {
                question?.type == "CODE" && question?.codeQuestion?.constraints &&
                <div className="input-formats">
                    <div className="header">Constraints:</div>
                    <div className="input-format">
                        {
                            question?.codeQuestion?.constraints.split('\n').map((str, i) => <p key={i}>{str}</p>)
                        }
                    </div>
                </div>
            }
            {
                question?.type == "CODE" &&
                <div className="sample-tests">
                    {
                        question?.codeQuestion?.sampleTestCases && question?.codeQuestion?.sampleTestCases.length > 0 &&
                        <>
                            <div className="header">Sample Test Case:</div>
                            <div key={question?.codeQuestion?.sampleTestCases[0].id} className="sample-test">
                                <div>Input {
                                    question?.codeQuestion?.sampleTestCases[0].input.split('\n').map((str, i) => <p key={i}>{str}</p>)
                                }
                                </div>
                                <div>Expected Output {
                                    question?.codeQuestion?.sampleTestCases[0].expectedOutput.split('\n').map((str, i) => <p key={i}>{str}</p>)
                                }</div>
                            </div>
                        </>
                    }
                    {/* TODO: later show all the sample test cases */}
                    {/* {
                            question?.codeQuestion?.sampleTestCases && question?.codeQuestion?.sampleTestCases.length > 0 &&
                            // question?.codeQuestion?.sampleTestCases.map((testcase) => {
                            //     return <div key={testcase.id} className="sample-test">
                            //         <div>Input {testcase.input}</div>
                            //         <div>Expected Output {testcase.expectedOutput}</div>
                            //     </div>
                            // })
                        } */}
                </div>
            }
        </div>
    );
};
export default QuestionDescription;

