import React, { useRef } from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";

export interface ModalProps {
    readonly setShowModal: (flag: boolean) => void
    children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ setShowModal, children }) => {
    // close the modal when clicking outside the modal.
    const modalRef = useRef();
    const closeModal = (e: any) => {
        if (e.target === modalRef.current) {
            setShowModal(false);
        }
    };

    //render the modal JSX in the portal div.
    return ReactDOM.createPortal(
        <div className="modal-container" ref={modalRef.current} onClick={closeModal}>
            <div className="modal">
                <div className="modal-header">
                    <span>Instructions</span>
                    <button onClick={() => setShowModal(false)}>X</button>
                </div>
                <div className="separator"></div>
                <div className="modalContent">
                    {children}
                </div>
            </div>
        </div>,
        document.getElementById("portal") as any
    );
};
export default Modal;

