import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { putCandidateProfile } from "../services/CandidateService";
import { UserContext, UserContextState } from "../contextApis/userContext";
import { useToastContext, ADD } from "../contextApis/toastContext";
import { useForm } from 'react-hook-form';
import { showToastMessageOnError } from "../utils/utils";
import { UserInfo } from "../sharedTypes";
import "./MyDetailsPage.scss";

const MyDetailsPage: React.FC = () => {
    const navigate = useNavigate();
    const formData = new FormData();
    const userContext = useContext<UserContextState>(UserContext);
    const { toastDispatch } = useToastContext();
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [startTest, setStartTest] = useState<boolean>(localStorage.getItem("candidateId") ? true : false);
    const { given_name, family_name, email } = userContext.getUserDetails();
    const [userInfo, setUserInfo] = useState<UserInfo>({
        firstName: given_name || "",
        lastName: family_name || "",
        userEmail: email || "",
        mobileNumber: localStorage.getItem("mobileNumber") || "",
        collegeName: localStorage.getItem("collegeName") || "",
        stream: localStorage.getItem("stream") || "",
        file: ""
    });
    const [showFileUrl, setShowFileUrl] = useState(localStorage.getItem("resumeUrl") || "");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = () => {
        userContext.changeLoaderStatus(true);
        formData.append("candidateId", localStorage.getItem("candidateId") || "");
        formData.append("firstName", userInfo.firstName || "");
        formData.append("lastName", userInfo.lastName || "");
        formData.append("mobileNumber", userInfo.mobileNumber || "");
        formData.append("collegeName", userInfo.collegeName || "");
        formData.append("stream", userInfo.stream || "");
        !showFileUrl && formData.append("resume", userInfo.file || "");

        putCandidateProfile(formData)
            .then((response: any) => {
                userContext.changeLoaderStatus(false);
                setStartTest(true);
                toastDispatch({
                    type: ADD,
                    payload: {
                        content: "User information submitted Successfully.",
                        type: "success"
                    }
                });
                if (response?.data) {
                    localStorage.setItem("candidateId", response?.data?.id as string);
                    localStorage.setItem("assessmentInProgress", response?.data?.assessmentInProgress as string);
                    localStorage.setItem("collegeName", response?.data?.collegeName as string);
                    localStorage.setItem("resumeUrl", response?.data?.resumeUrl as string);
                    localStorage.setItem("stream", response?.data?.stream as string);
                    localStorage.setItem("mobileNumber", response?.data?.mobileNumber as string);
                }
            }).catch((error: any) => {
                showToastMessageOnError(error, userContext, toastDispatch, ADD);
            });
    }

    const handleChange = (event: any) => {
        const { name, value, type } = event.target;
        setUserInfo({
            ...userInfo,
            [name]: value,
        });
    };
    const handleKeyDown = (event: any) => {
        const { name, value, type } = event.target;
        if (value.length == 10 && event.keyCode != 8)
            event.preventDefault();
    }

    const onFileChange = (event: any) => {
        setFileUploaded(false);
        setUserInfo({
            ...userInfo,
            file: event.target?.files[0]
        });
        if (event.target?.files.length > 0 && event.target?.files[0].size < 307200) {
            setFileUploaded(true);
            setShowFileUrl("");
            delete errors?.resume;
        }
        else {
            setFileUploaded(false)
        }
    }

    const onStartTest = () => {
        if (localStorage.getItem("candidateId") && localStorage.getItem("candidateId") != "")
            // TODO: check for Exam already taken for user flow
            navigate("/questions");
        else {
            toastDispatch({
                type: ADD,
                payload: {
                    content: "Something went wrong. Please try again.",
                    type: "danger"
                }
            });
            userContext.onLogout();
        }
    }

    let fileInputProps: any = {};
    fileInputProps = localStorage.getItem("resumeUrl") === null && {
        ...register("resume", {
            required: true,
            validate: {
                lessthan300KB: files => files[0].size < 307200
            }
        })
    }

    return (
        <div className="MyDetailsPage">
            <span className="label">My Details</span>
            <form id="user-details" className="ui form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="field">
                        <label>* First Name</label>
                        <input
                            placeholder='First Name'
                            type="text"
                            defaultValue={userInfo.firstName}
                            readOnly={true}
                            {...register("firstName", { required: true })}
                        />
                        {errors.firstName && <p className="form-error">Please add First Name</p>}
                    </div>
                    <div className="field">
                        <label>* Last Name</label>
                        <input
                            placeholder='Last Name'
                            type="text"
                            defaultValue={userInfo.lastName}
                            readOnly={true}
                            {...register("lastName", { required: true })}
                        />
                        {errors.lastName && <p className="form-error">Please add Last Name</p>}
                    </div>
                </div>
                <div className="row">
                    <div className="field">
                        <label>* Email Address</label>
                        <input
                            placeholder='Email Address'
                            type="email"
                            defaultValue={userInfo.userEmail}
                            readOnly={true}
                        />
                        {errors.userEmail && <p className="form-error">Please check the Email Address</p>}
                    </div>
                    <div className="field">
                        <label>* Mobile Number</label>
                        <input
                            placeholder='Phone Number'
                            type="number"
                            defaultValue={userInfo.mobileNumber}
                            {...register("mobileNumber", { required: true, maxLength: 10 })}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />
                        {errors.mobileNumber && <p className="form-error">Please add 10 digit Mobile Number</p>}
                    </div>
                </div>
                <div className="row">
                    <div className="field">
                        <label>* College Name</label>
                        <input
                            placeholder="College Name"
                            type="text"
                            defaultValue={userInfo.collegeName}
                            {...register("collegeName", { required: true })}
                            onChange={handleChange}
                        />
                        {errors.collegeName && <p className="form-error">Please add College Name</p>}
                    </div>
                    <div className="field">
                        <label>* Stream</label>
                        <input
                            placeholder="Stream"
                            type="text"
                            defaultValue={userInfo.stream}
                            {...register("stream", { required: true })}
                            onChange={handleChange}
                        />
                        {errors.stream && <p className="form-error">Please add Stream for Degree</p>}
                    </div>
                </div>
                <div className="field">
                    <label>* Upload Resume</label>
                    <input
                        placeholder='Upload Resume'
                        type="file"
                        accept=".pdf"
                        {...fileInputProps}
                        onChange={onFileChange}
                    />
                    <label className="file-size-info">PDF file size should be less than 300 KB</label>
                    {
                        showFileUrl &&
                        <div><label className="upload-status">File uploaded</label><a href={localStorage.getItem("resumeUrl") || ""} rel="noreferrer" target="_blank">{userInfo?.firstName}_{userInfo.lastName}_resume.pdf</a></div>
                    }
                    {fileUploaded &&
                        <label className="upload-status">File uploaded</label>}
                    {errors.resume && <p className="form-error">Please upload Resume less than 300 KB</p>}
                </div>
            </form>
            <div className="form-actions">
                <input className="ui button" type="submit" form="user-details" value="Save" />
                <input className="ui button" type="button" value="Start Test" disabled={!startTest} onClick={onStartTest} />
            </div>
        </div>
    )
};
export default MyDetailsPage;

