import React  from "react";
import "./MarkingSchemeCard.scss";

interface MarkingSchemeCardProps {
    readonly level: string;
    readonly type: string;
    readonly questions: number;
    readonly marks: number;
    readonly color: string;
    readonly bgColor: string;
    readonly bxShdw: string;
}

export const MarkingSchemeCard :React.FC<MarkingSchemeCardProps> = ({ level, type, questions, marks, color, bgColor, bxShdw }) => {
    const each = marks/questions;
    return (
    <div className="MarkingSchemeCard">
        <div className="block" style={{backgroundColor: bgColor, color: color, boxShadow: `0px 1px 6px ${bxShdw}`}}></div>
        <div className="text">
            <div className="level" style={{backgroundColor: color}}>
                {level}
                <div className="fold"></div>
            </div>
            <div className="heading">{questions} {type} Questions</div>
            <div className="marks" style={{color}}>
                <span className="total">{marks} Mark{marks > 1 ? "s" : ""}</span>
                <span className="each">({each} mark{each > 1 ? "s" : ""} each)</span>
            </div>
        </div>
    </div>)
}