import React, { useState } from "react";
import "./SearchControl.scss";

export interface searchControlProps {
    readonly saveSearchText: (text: string) => void;
    readonly setCurrentPage: (currentPage: number) => void;
}

const SearchControl: React.FC<searchControlProps> = ({ saveSearchText, setCurrentPage }) => {
    const [searchText, setSearchText] = useState("");

    const handleChange = (event: any) => {
        setSearchText(event?.target?.value);
    }
    const handleKeyDown = (event: any) => {
        if (event.key == "Enter") {
            getCandidateList(event?.target?.value)
        }
    }
    const getCandidateList = (text: string) => {
        setSearchText(text);
        saveSearchText(text);
        setCurrentPage(1);
    }
    return (
        <div className="searchControl">
            <div className="search-field">
                <div className="input-group">
                    <input
                        type="text"
                        className="input-field"
                        placeholder="Search by Name..."
                        aria-label="Search"
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                    <button type="button" id="btn-search" onClick={() => getCandidateList(searchText)}>Search</button>
                </div>
            </div>
        </div>
    );
};
export default SearchControl;

