import React, { createContext, useReducer, useContext } from "react";
import { createPortal } from "react-dom";
import Toast from "../components/Toast";

const initialState: any = [];

export const ToastContext = createContext(initialState);
ToastContext.displayName = "ToastContext";



export const ADD = "ADD";
export const REMOVE = "REMOVE";
export const REMOVE_ALL = "REMOVE_ALL";

export const toastReducer = (state: any, action: any) => {
    switch (action.type) {
        case ADD:
            return [
                ...state,
                {
                    id: +new Date(),
                    content: action.payload.content,
                    type: action.payload.type
                }
            ];
        case REMOVE:
            return state.filter((t: any) => t.id !== action.payload.id);
        case REMOVE_ALL:
            return initialState;
        default:
            return state;
    }
};

interface Props {
    children: React.ReactNode
}

export const ToastProvider: React.FC<Props> = (props) => {
    const [toast, toastDispatch] = useReducer(toastReducer, initialState);
    const toastData = { toast, toastDispatch };
    return (
        <ToastContext.Provider value={toastData}>
            {props.children}
            {createPortal(<Toast toast={toast} />, document.body)}
        </ToastContext.Provider>
    );
};

export const useToastContext = () => {
    return useContext(ToastContext);
};
