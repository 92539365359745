import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { DefaultLayout } from "../layouts/DefaultLayout";
import InstructionsPage from "../pages/InstructionsPage";
import MyDetailsPage from "../pages/MyDetailsPage";
import SearchPage from "../pages/SearchPage";
import QuestionsPage from "../pages/QuestionsPage";
import SubmitSuccessPage from "../pages/SubmitSuccessPage";
import RootPage from "../pages/RootPage";
import CallbackPage from "../pages/CallbackPage";

const PrivateRoute = ({ children }: any) => {
    const isAuthenticated = Boolean(localStorage.getItem("IdToken"));
    return isAuthenticated ? <DefaultLayout>{children}</DefaultLayout> : <Navigate to="/" replace />
};

export const UserRoutes: React.FC = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<RootPage />} />
                <Route
                    path="/callback"
                    element={<CallbackPage />}
                />
                <Route
                    path="/instructions"
                    element={<PrivateRoute><InstructionsPage /></PrivateRoute>}
                />
                <Route
                    path="/my-details"
                    element={<PrivateRoute><MyDetailsPage /></PrivateRoute>}
                />
                <Route
                    path="/search"
                    element={<PrivateRoute><SearchPage /></PrivateRoute>}
                />
                <Route
                    path="/questions"
                    element={<PrivateRoute><QuestionsPage /></PrivateRoute>}
                />
                <Route
                    path="/submit-success/:status"
                    element={<PrivateRoute><SubmitSuccessPage /></PrivateRoute>}
                />
            </Routes>
        </>
    );
};
