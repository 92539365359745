import React, { useContext } from "react";
import SpinnerIcon from "../assets/imgs/spinner.gif";
import SkateboardingIcon from "../assets/imgs//skateboarding.gif"
import { UserContext, UserContextState } from "../contextApis/userContext"
import "./Loader.scss";

const Loader: React.FC = () => {
    const userContext = useContext<UserContextState>(UserContext);
    return userContext.loading ? (
        <div className="loader-container">
            <div className="loader">
                <img src={SkateboardingIcon} alt="" />
            </div>
        </div>
    ) : null;
};

export default Loader;