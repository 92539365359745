import axios from 'axios';
import { COGNITO_LOGIN_URL } from "../utils/utils";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_HOST
});

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
    config["headers"] = {
        'Authorization': `Bearer ${localStorage.getItem("IdToken")}`,
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, async function (error) {
    if (error?.response?.status == 401) {
        localStorage.clear();
        window.location.href = COGNITO_LOGIN_URL;
    }
    return Promise.reject(error);
});

const axiosForMultipartInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_HOST,
    headers: {
        'Content-Type': "multipart/form-data",
    }
});

// Add a request interceptor
axiosForMultipartInstance.interceptors.request.use(function (config) {
    config["headers"] = {
        'Authorization': `Bearer ${localStorage.getItem("IdToken")}`,
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Add a response interceptor
axiosForMultipartInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
}, async function (error) {
    if (error?.response?.status == 401) {
        localStorage.clear();
        window.location.href = COGNITO_LOGIN_URL;
    }
    return Promise.reject(error);
});



export {
    axiosInstance,
    axiosForMultipartInstance
}
