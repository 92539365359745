import React, { useContext } from "react";
import "./AssessmentReview.scss";
import { APPROVED, REJECTED } from "../sharedTypes";
import { patchCandidateReview } from "../services/AdminService";
import { UserContext, UserContextState } from "../contextApis/userContext";
import { ExamContext, ExamContextState } from "../contextApis/examContext";
import { useToastContext, ADD } from "../contextApis/toastContext";
import { showToastMessageOnError } from "../utils/utils";
import { useNavigate } from "react-router";

const AssessmentReview: React.FC = () => {
    const userContext = useContext<UserContextState>(UserContext);
    const examContext = useContext<ExamContextState>(ExamContext);
    const { toastDispatch } = useToastContext();
    const navigate = useNavigate();

    const onAssessmentReview = (action: string) => {
        console.log("action", action)
        userContext.changeLoaderStatus(true);
        const candidateAssessmentId: string = examContext?.examInfo?.candidateAssessmentId || "";
        if (candidateAssessmentId) {
            patchCandidateReview(candidateAssessmentId, action)
                .then((response: any) => {
                    userContext.changeLoaderStatus(false);
                    navigate(`/search`);
                    if (response?.status == 204) {
                        toastDispatch({
                            type: ADD,
                            payload: {
                                content: "Submitted Successfully.",
                                type: "success"
                            }
                        })
                    }
                }).catch((error: any) => {
                    showToastMessageOnError(error, userContext, toastDispatch, ADD);
                });
        }
    }

    return (
        <div className="assessment-review">
            <button id="btn-reject" onClick={() => onAssessmentReview(REJECTED)}>Reject</button>
            <button id="btn-submit" onClick={() => onAssessmentReview(APPROVED)}>Shortlist</button>
        </div>
    );
};
export default AssessmentReview;

