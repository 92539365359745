import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../hooks/usePagination';
import './Pagination.scss';

export interface paginationProps {
    readonly onPageChange: (currentPage: number) => void;
    readonly totalCount: number;
    readonly currentPage: number;
    readonly pageSize: number;
}

const Pagination: React.FC<paginationProps> = ({ currentPage, totalCount, pageSize, onPageChange }) => {
    const paginationRange: any = usePagination(
        totalCount,
        pageSize,
        currentPage
    );

    if (paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const lastPage = paginationRange[paginationRange?.length - 1];
    return (
        <ul
            className={classnames('pagination-container')}
        >
            <li
                className={classnames('pagination-item', {
                    disabled: currentPage === 1
                })}
                onClick={onPrevious}
            >
                <div className="arrow left" />
            </li>
            {paginationRange.map((pageNumber: any, index: number) => {
                if (pageNumber === DOTS) {
                    return <li key={index} className="pagination-item dots">&#8230;</li>;
                }

                return (
                    <li key={index}
                        className={classnames('pagination-item', {
                            selected: pageNumber === currentPage
                        })}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                className={classnames('pagination-item', {
                    disabled: currentPage === lastPage
                })}
                onClick={onNext}
            >
                <div className="arrow right" />
            </li>
        </ul>
    );
};

export default Pagination;
