export const InstructionList = [
    {
        id: "1",
        description: "Please use laptop/desktop to take this test. This site is optimized for 1440 and higher resolution. (Hint: Try zooming out to fit the app on resolutions less than 1440.)"
    },
    {
        id: "2",
        description: "Make sure you do not modify/overwrite the class/method name, number of parameters, and return type."
    },
    {
        id: "3",
        description: "Once you have written your code inside the template, hit the 'Save and Run' button to save and run your code."
    },
    // {
    //     id: "4",
    //     description: "While coding in Python, make sure that correct indentation (4 spaces) is used in the code."
    // },
    // {
    //     id: "5",
    //     description: `While coding in C++, make sure that if question states that an array is expected as return value, DON'T send array, instead join the elements inside the array using ", as delimiter and return a string.`
    // }
]