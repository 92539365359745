import React, { useEffect, useState } from "react";
import ReviewLaterIcon from "../assets/imgs/review_later.svg";
import { QuestionList } from "../sharedTypes";
import { MarkingList } from "../data/markingList";
import "./Question.scss";

export interface questionprops {
    readonly question?: QuestionList;
    readonly index?: number;
}

// Type: MCQ && Difficulty Level: Easy = Easy
// Type: MCQ && Difficulty Level: Medium = Simple
// Type: Code && Difficulty Level: Easy = Medium
// Type: Code && Difficulty Level: Medium = Complex

const Question: React.FC<questionprops> = ({ question, index }) => {
    const [questionNumber, setQuestionNumber] = useState(0);
    const [color, setColor] = useState("#C62828");
    const [bgColor, setBgCcolor] = useState("#FFF0F0");

    let questionDifficultyLevel = "";

    useEffect(() => {
        if (question?.type == "MCQ") {
            if (question?.difficultyLevel.toLowerCase() == "easy")
                questionDifficultyLevel = "easy"
            if (question?.difficultyLevel.toLowerCase() == "medium")
                questionDifficultyLevel = "simple"
        }
        if (question?.type == "CODE") {
            if (question?.difficultyLevel.toLowerCase() == "easy")
                questionDifficultyLevel = "medium"
            if (question?.difficultyLevel.toLowerCase() == "medium")
                questionDifficultyLevel = "complex"
        }
        const questionMarking: any = MarkingList.filter((marking) => {
            return marking.level.toLowerCase() == questionDifficultyLevel
        })[0];
        if (questionMarking) {
            setColor(questionMarking?.color)
            setBgCcolor(questionMarking?.bgColor)
        }
    }, [])

    useEffect(() => {
        index ? setQuestionNumber(index + 1) : setQuestionNumber(1)
    }, [index])



    return (
        <div className="question">
            <div className="question-info">
                <div className="question-wrpr">
                    <span>Q{questionNumber}</span>
                    <span style={{ backgroundColor: bgColor, color: color }}>
                        {
                            localStorage.getItem("userRole")?.includes("INTERVIEWER") ?
                                `${question?.obtainedMarks && question?.obtainedMarks >= 0 ? question?.obtainedMarks : 0}/${question?.maxMarks}`
                                :
                                question?.maxMarks
                        } Marks</span>
                </div>
                {
                    localStorage.getItem("userRole")?.includes("CANDIDATE") && question?.reviewLater &&
                    <div className="review-mark">
                        <img src={ReviewLaterIcon} alt="Review Later" />
                    </div>
                }
            </div>
        </div>
    );
};
export default Question;

