import React, { useRef } from "react";
import ReactDOM from "react-dom";
import "./ConfirmationModal.scss";

export interface ConfrimationModalProps {
    readonly setShowConfirmationModal: (flag: boolean) => void,
    children: React.ReactNode,
    readonly onConfirm: (flag: boolean) => void
}

const ConfirmationModal: React.FC<ConfrimationModalProps> = ({ setShowConfirmationModal, children, onConfirm }) => {
    // close the modal when clicking outside the modal.
    const modalRef = useRef();
    const closeModal = (e: any) => {
        if (e.target === modalRef.current) {
            setShowConfirmationModal(false);
        }
    };

    //render the modal JSX in the portal div.
    return ReactDOM.createPortal(
        <div className="confirmation-container" ref={modalRef.current} onClick={closeModal}>
            <div className="confirmation-modal">
                <div className="confirmation-modal-header">
                    <span>Confirm Submit</span>
                    <button className="close-button" onClick={() => setShowConfirmationModal(false)}>X</button>
                </div>
                <div className="separator"></div>
                <div className="modalContent">
                    {children}
                </div>
                <div className="modal-action">
                    <button onClick={() => onConfirm(true)}>Confirm</button>
                </div>
            </div>
        </div>,
        document.getElementById("portal") as any
    );
};

export default ConfirmationModal;

