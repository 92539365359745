import jwtDecode from "jwt-decode";
import moment from "moment";

const REACT_APP_COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
const REACT_APP_COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
const REACT_APP_COGNITO_CALLBACK_URL = process.env.REACT_APP_COGNITO_CALLBACK_URL;

export const COGNITO_LOGIN_URL = `https://${REACT_APP_COGNITO_DOMAIN}.auth.${REACT_APP_COGNITO_REGION}.amazoncognito.com/login?client_id=${REACT_APP_COGNITO_CLIENT_ID}&response_type=token&scope=email+openid+profile&redirect_uri=${REACT_APP_COGNITO_CALLBACK_URL}`

export const showToastMessageOnError = (error: any, userContext: any, toastDispatch: any, type: any) => {
    if (error?.response?.status == 401) {
        setTimeout(() => {
            userContext.changeLoaderStatus(false);
        }, 5000);
        toastDispatch({
            type: type,
            payload: {
                content: "'Google Signing in' in progress. Please try again after successful sign in.",
                type: "info"
            }
        });
    }
    else {
        userContext.changeLoaderStatus(false);
        toastDispatch({
            type: type,
            payload: {
                content: "Something went wrong. Please try again.",
                type: "danger"
            }
        });
    }
}

export const isUserAuthenticated = () => {
    if (localStorage.getItem("IdToken")) {
        const decoded: any = jwtDecode(localStorage.getItem("IdToken") as string);
        //console.log("moment().valueOf()", moment().valueOf())
        //console.log("decoded.exp", decoded.exp * 1000)
        return moment().valueOf() < decoded.exp * 1000;
        //return Boolean(localStorage.getItem("IdToken"))
    }
    return false
}

