import React, { useState, useEffect, useRef } from "react";
import TimerIcon from "../assets/imgs/timer.svg";
import { useNavigate } from "react-router";
import "./Timer.scss";

export interface TimerProps {
    readonly examStartTime?: string
}

const Timer: React.FC<TimerProps> = ({ examStartTime }) => {
    const [timer, setTimer] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        // (async () => {
        //     await getStartTime();
        // })();
        getStartTime();
        const interval = setInterval(() => {
            setTimer((timer) => {
                validate(timer - 1);
                return timer - 1;
            });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [examStartTime]);

    const validate = (timer: any) => {
        if (timer <= 0) {
            navigate("/submit-success/submitted")
        }
    }

    const getStartTime = () => {
        const startTime = new Date(examStartTime || "").getTime();
        const currentTime = new Date().getTime();
        const timerValue = Math.round((currentTime - startTime) / 1000);
        // TODO: check exam time
        setTimer(120 * 60 - timerValue);
    }

    const getFormattedTime = () => {
        const mm = Math.floor(timer / 60);
        const ss = timer - mm * 60;
        return `${mm} min : ${ss} sec`;
    }

    return (
        <>
            {
                examStartTime ?
                    <span className="timer">
                        <img src={TimerIcon} alt="Tech Prescient" />
                        <span>{getFormattedTime()}</span>
                    </span>
                    : null
            }
        </>
    );
};

export default Timer;