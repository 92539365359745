
export interface UserInfo {
    readonly firstName?: string;
    readonly lastName?: string;
    readonly userEmail?: string;
    readonly mobileNumber?: string;
    readonly collegeName?: string;
    readonly stream?: string;
    readonly file?: string;
}

export interface SampleTestCases {
    readonly expectedOutput: string;
    readonly id: string;
    readonly input: string;
}

export interface CodeQuestion {
    readonly sampleTestCases: SampleTestCases[];
    readonly inputFormat?: string;
    readonly outputFormat?: string;
    readonly constraints?: string;
    readonly explanation?: string;
}

export interface CodeSolution {
    readonly answer: string;
    readonly codingLanguageId: string;
}

export interface MCQOptions {
    readonly value: string;
    readonly id: string;
    readonly isAnswer?: boolean;
}

export interface MCQQuestion {
    readonly mcqType: string;
    readonly options: MCQOptions[];
}

export interface MCQSolution {
    readonly selectedOptions: string[];
}

export interface QuestionList {
    readonly approxRequiredTimeSecs?: number;
    readonly codeQuestion: CodeQuestion;
    codeSolution: CodeSolution;
    readonly difficultyLevel: string;
    readonly id: string;
    readonly maxMarks: number;
    readonly mcqQuestion: MCQQuestion;
    mcqSolution: MCQSolution;
    readonly questionId: string;
    readonly title?: string;
    readonly description?: string;
    readonly type: string;
    reviewLater?: boolean;
    obtainedMarks?: number;
}

export interface ExamInfo {
    readonly examStartTime: string;
    readonly status: string;
    readonly candidateAssessmentId: string;
    readonly totalQuestions: number;
}

export interface DropDownOption {
    readonly value?: string;
    readonly label?: string;
    readonly codeEditorMode?: string;
    readonly codeSckeleton?: string;
}

export interface RunCodeResponse {
    readonly error?: string;
    readonly errorType?: string;
    readonly expectedOutput?: string;
    readonly matchesExpectedOutput?: boolean;
    readonly memory?: string;
    readonly providedInput?: string;
    readonly returnedOutput?: string;
    readonly time?: string;
}

export interface candidateAssessment {
    readonly assessmentId?: string;
    readonly submittedOn?: string;
    readonly candidateAssessmentStatus?: string;
    readonly candidateReviewedStatus?: string;
    readonly totalMarks?: number;
}

export interface ExamSession {
    readonly candidateAssessment?: candidateAssessment;
}

export interface CandidateList {
    readonly name?: string;
    readonly emailId?: string;
    readonly mobileNumber?: number;
    readonly collegeName?: string;
    readonly stream?: string;
    readonly resumeUrl?: string;
    readonly examSession?: ExamSession;
}

export interface CandidateListCount {
    numberOfElements: number,
    totalElements: number,
    currentPage: number,
    size: number,
    totalPages: number
}

export interface CandidateInfo {
    readonly assessmentId?: string;
    readonly name?: string;
    readonly emailId?: string;
    readonly resumeUrl?: string;
    readonly totakMarks?: number;
}

export const CANDIDATE = "CANDIDATE";
export const INTERVIEWER = "INTERVIEWER";

export const REJECTED = "REJECTED";
export const APPROVED = "APPROVED";