import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext, UserContextState } from "../contextApis/userContext";
import { getUserRole } from "../services/CandidateService";
import { useToastContext, ADD } from "../contextApis/toastContext";
import { COGNITO_LOGIN_URL, showToastMessageOnError } from "../utils/utils";
import { CANDIDATE, INTERVIEWER } from "../sharedTypes";

const RootPage: React.FC = () => {
    const userContext = useContext<UserContextState>(UserContext);
    const navigate = useNavigate();
    const { toastDispatch } = useToastContext();

    useEffect(() => {
        const idToken = localStorage.getItem("IdToken");
        if (idToken) {
            getUserRole().then((response: any) => {
                localStorage.setItem("userRole", response?.data?.roles);
                if (response?.data?.roles.includes(INTERVIEWER)) {
                    navigate("/search");
                }
                if (response?.data?.roles.includes(CANDIDATE)) {
                    navigate("/instructions");
                }
                if (response?.data?.candidate) {
                    localStorage.setItem("candidateId", response?.data?.candidate?.id as string);
                    localStorage.setItem("assessmentInProgress", response?.data?.candidate?.assessmentInProgress as string);
                    localStorage.setItem("collegeName", response?.data?.candidate?.collegeName as string);
                    localStorage.setItem("resumeUrl", response?.data?.candidate?.resumeUrl as string);
                    localStorage.setItem("stream", response?.data?.candidate?.stream as string);
                    localStorage.setItem("mobileNumber", response?.data?.candidate?.mobileNumber as string);
                }
            }).catch((error: any) => {
                showToastMessageOnError(error, userContext, toastDispatch, ADD);
            });
        }
        else {
            localStorage.clear();
            window.location.href = COGNITO_LOGIN_URL;
        }
    }, [])

    return (
        <></>
    );
};
export default RootPage;

