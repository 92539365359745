import React, { useContext, useState } from "react";
import { CandidateList, CandidateListCount } from "../sharedTypes";
import { useNavigate } from "react-router";
import "./CandidateTable.scss";
import moment from "moment";
import Pagination from "./Pagination";

export interface candidateListProps {
    readonly candidateList: CandidateList[];
    readonly candidateListCount?: CandidateListCount;
    readonly currentPage: number;
    readonly pageSize: number;
    readonly setCurrentPage: (currentPage: number) => void;
}

const CandidateTable: React.FC<candidateListProps> = ({ candidateList, candidateListCount, currentPage, pageSize, setCurrentPage }) => {
    const navigate = useNavigate();
    const callCandidateAssessment = (candidateList: CandidateList) => {
        if (candidateList?.examSession?.candidateAssessment?.assessmentId) {
            navigate(`/questions`, {
                state:
                {
                    assessmentId: candidateList?.examSession?.candidateAssessment?.assessmentId,
                    name: candidateList?.name,
                    emailId: candidateList?.emailId,
                    resumeUrl: candidateList?.resumeUrl,
                    totalMarks: candidateList?.examSession?.candidateAssessment?.totalMarks
                }
            })
        }
    }

    const formatDate = (date: string) => {
        return date && date != "" && moment(date).format('MM/DD/YYYY') || ""
    }

    return (
        <div className="candidateTable">
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Submitted On</th>
                        <th scope="col">Marks</th>
                        <th scope="col">Email ID</th>
                        <th scope="col">Mobile Number</th>
                        <th scope="col">College Name</th>
                        <th scope="col">Stream</th>
                        <th scope="col">Assessment Status</th>
                        <th scope="col">Reviewed Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        candidateList && candidateList?.length > 0 &&
                        candidateList?.map((candidate) => {
                            return <tr key={candidate?.emailId}
                                onClick={() => callCandidateAssessment(candidate as CandidateList)}>
                                <td>{candidate?.name}</td>
                                <td>{formatDate(candidate?.examSession?.candidateAssessment?.submittedOn as string)}</td>
                                <td>{candidate?.examSession?.candidateAssessment?.totalMarks}</td>
                                <td>{candidate?.emailId}</td>
                                <td>{candidate?.mobileNumber}</td>
                                <td>{candidate?.collegeName}</td>
                                <td>{candidate?.stream}</td>
                                <td className="evaulated-status">
                                    <span className={candidate?.examSession?.candidateAssessment?.candidateAssessmentStatus}>
                                        {candidate?.examSession?.candidateAssessment?.candidateAssessmentStatus}
                                    </span>
                                </td>
                                <td className="review_status">
                                    <span className={candidate?.examSession?.candidateAssessment?.candidateReviewedStatus}>
                                        {candidate?.examSession?.candidateAssessment?.candidateReviewedStatus}
                                    </span>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
            {
                candidateList && candidateList.length > 0 &&
                <Pagination
                    currentPage={currentPage}
                    totalCount={candidateListCount?.totalElements as number}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            }
        </div>
    );
};
export default CandidateTable;

