import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { InstructionList } from "../data/instructionList";
import { MarkingList } from "../data/markingList";
import MarkDetailIcon from "../assets/imgs/mark_details.svg";
import TimerIcon from "../assets/imgs/total_time.svg";
import { MarkingSchemeCard } from "../components/MarkingSchemeCard";
import { UserContext, UserContextState } from "../contextApis/userContext";
import "./InstructionsPage.scss";

const InstructionsPage: React.FC = () => {
    const navigate = useNavigate();
    const userContext = useContext<UserContextState>(UserContext);
    const totalMarks = MarkingList.reduce((acc, { marks }) => acc + marks, 0);
    const onUploadDetails = () => {
        localStorage.getItem("assessmentInProgress") && localStorage.getItem("assessmentInProgress") == "true" ?
            navigate("/questions") :
            navigate("/my-details");
    }

    return (
        <div className="InstructionsPage">
            <div className="instr-wrpr">
                <span className="instr-lbl">Instructions</span>
                <ul>
                    {InstructionList.map(({ id, description }, index) =>
                        <li key={id}>{description}</li>
                    )}
                </ul>
            </div>
            <div className="mrking-schm-wrpr">
                <div className="mrking-schm-lbl">Marking Scheme</div>
                {MarkingList.map(({ level, type, questions, marks, color, bgColor, bxShdw }, idx) =>
                    <MarkingSchemeCard key={idx} level={level} type={type} questions={questions} marks={marks} color={color} bgColor={bgColor} bxShdw={bxShdw} />
                )}
                <div className="mrking-schm-smry">
                    <img src={MarkDetailIcon} alt="Mark Details" />
                    <span>{totalMarks} Marks</span>
                    <span className="separator"></span>
                    <img src={TimerIcon} alt="Total Test Time" />
                    <span>02:00:00 Hours</span>
                </div>
                <button id="upload" onClick={onUploadDetails}>Upload details</button>
            </div>
        </div>
    );
};
export default InstructionsPage;

