import React from "react";
import Select from 'react-select'
import { DropDownOption } from "../sharedTypes";


export interface questionprops {
    readonly theme?: string;
    readonly options?: DropDownOption[];
    readonly selectedLanguage?: DropDownOption;
    readonly handleLanguageChange: (language: string) => void
}

const DropDownComponent: React.FC<questionprops> = ({ theme, options, selectedLanguage, handleLanguageChange }) => {
    const old_options = [
        {
            value: 'java',
            label: "Java",
            // TODO: Language Icon for Option
            // icon: <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
            //     <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            // </svg>
        }
    ]
    // TODO: Language Icon for Option

    const handleChange = (e: any) => {
        //setSelectedOption(e);
        handleLanguageChange(e);
    }

    // TODO: Make CSS paramarized if required to use with light theme
    const customStyles = theme === "dark" ?
        {
            control: (base: any, state: any) => ({
                ...base,
                width: "200px",
                background: "#2C2C2C",
                borderColor: "#BDBDBD",
                //boxShadow: state.isFocused ? null : null,
                boxShadow: null,
                "&:hover": {
                    borderColor: "#BDBDBD"
                }
            }),
            placeholder: (base: any) => ({
                ...base,
                color: "#FFF"
            }),
            menu: (base: any) => ({
                ...base,
                borderRadius: 0,
                marginTop: 0,
                background: "#2C2C2C"
            }),
            dropdownIndicator: (base: any) => ({
                ...base,
                color: "#565454",
                "&:hover": {
                    color: "#FFF"
                }
            }),
            menuList: (base: any) => ({
                ...base,
                padding: 0,
                marginTop: 0
            }),
            option: (provided: any, state: any) => ({
                ...provided,
                color: '#FFF',
                background: state.isSelected ? '#565454' : '#000',
                "&:hover": {
                    background: "#565454"
                }
            }),
            singleValue: (provided: any, state: any) => ({
                ...provided,
                color: "#FFF"
            })
        } :
        // TODO: css for light theme
        {
            control: (base: any, state: any) => ({
                ...base,
                width: "200px",
                background: "#2C2C2C",
                borderColor: "#BDBDBD",
                //boxShadow: state.isFocused ? null : null,
                boxShadow: null,
                "&:hover": {
                    borderColor: "#BDBDBD"
                }
            }),
            placeholder: (base: any) => ({
                ...base,
                color: "#FFF"
            }),
            menu: (base: any) => ({
                ...base,
                borderRadius: 0,
                marginTop: 0,
                background: "#2C2C2C"
            }),
            dropdownIndicator: (base: any) => ({
                ...base,
                color: "#565454",
                "&:hover": {
                    color: "#FFF"
                }
            }),
            menuList: (base: any) => ({
                ...base,
                padding: 0,
                marginTop: 0
            }),
            option: (provided: any, state: any) => ({
                ...provided,
                color: '#FFF',
                background: state.isSelected ? '#565454' : '#000',
                "&:hover": {
                    background: "#565454"
                }
            }),
            singleValue: (provided: any, state: any) => ({
                ...provided,
                color: "#FFF"
            })
        };

    return (
        <Select
            options={options}
            className="select-dropdown"
            // TODO: Enable to use language icon in option
            // getOptionLabel={
            //     e => (
            //         <div style={{ display: 'flex', alignItems: 'center' }}>
            //             {e.icon}
            //             <span style={{ marginLeft: 5 }}>{e.label}</span>
            //         </div> as any
            //     )
            // }
            styles={customStyles}
            value={selectedLanguage?.value ? selectedLanguage : null}
            onChange={handleChange}
        />
    );
};
export default DropDownComponent;

