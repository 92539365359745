import React, { useEffect } from "react";
import { useToastContext, REMOVE, REMOVE_ALL } from "../contextApis/toastContext";
import "./Toast.scss";

export interface ToastProps {
    readonly toast?: [];
}

const Toast: React.FC<ToastProps> = ({ toast }) => {
    const { toastDispatch } = useToastContext();
    useEffect(() => {
        setTimeout(() => {
            toastDispatch({
                type: REMOVE_ALL,
            })
        }, 3000);
    }, [toast])

    return (
        <div className="toast">
            <div className="toast-container">
                {toast && toast.map((t: any) => {
                    return (
                        <div
                            className={`toast-container-item ${t.type ? t.type : ""}`}
                            key={t.id}
                        >
                            {/* {renderItem(t.content)} */}
                            {
                                <div className="toast-content">{t.content}</div>
                            }
                            <div
                                role="img"
                                aria-label="close toast"
                                className="toast-close"
                                onClick={() =>
                                    toastDispatch({ type: REMOVE, payload: { id: t.id } })
                                }
                            >
                                &times;
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Toast
